.App {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background-color: #444;
}


.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-container {
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.06);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
  padding: 24px;
}

.List {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.List-Item {
  display: flex ;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 24px;
}

.List-Item-Label{
  font-size: 16px;
  margin-bottom: 6px;
  margin-left: 2px;
}

.List-Item-Value {
  font-size: 20px;
  font-weight: bold;
  width: 200px;
}